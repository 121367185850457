import { Cookies } from "react-cookie-consent";
import { setCookie } from "cookies-next";
import { datadogRum } from "@datadog/browser-rum";

export function getAccessToken() {
  if (!!Cookies.get("token_type") && Cookies.get("access_token")) {
    return Cookies.get("token_type") + " " + Cookies.get("access_token");
  } else {
    return "";
  }
}
export function clearToken() {
  Cookies.set("token_type", null, {
    expires: -1,
    domain: getDomain(),
  });
  Cookies.set("access_token", null, {
    expires: -1,
    domain: getDomain(),
  });
  Cookies.set("refresh_token", null, {
    expires: -1,
    domain: getDomain(),
  });
  Cookies.set("user_id", null, {
    expires: -1,
    domain: getDomain(),
  });
  datadogRum.clearUser();
  localStorage.removeItem("refresh_token");
}

export function setRefreshToken(jsonStr, req, res) {
  if (jsonStr) {
    const token = JSON.parse(jsonStr);
    const domain = convertDomain(req.headers["host"]);
    setCookie("token_type", token.token_type, {
      req,
      res,
      maxAge: token.expires_in,
      domain: domain,
    });
    setCookie("access_token", token.access_token, {
      req,
      res,
      maxAge: token.expires_in,
      domain: domain,
    });
    setCookie("refresh_token", token.refresh_token, {
      req,
      res,
      maxAge: token.refresh_token_expires_in,
      domain: domain,
    });
  }
}

export function getDomain() {
  return convertDomain(location.hostname);
}

export function convertDomain(domain) {
  if (domain.includes("tripbtoz")) {
    return "tripbtoz.com";
  } else if (domain.includes("ttbb")) {
    return "kbttbb.com";
  } else if (domain.includes("localhost")) {
    return "localhost";
  } else {
    return domain;
  }
}

export async function setCookieSignInViaApp(json, router, returnUrl) {
  let item = json;
  if (typeof json === "string") {
    item = JSON.parse(json);
  }
  if (item.userId) {
    Cookies.set("user_id", item.userId, {
      expires: 1 / 24,
      domain: getDomain(),
    });
    datadogRum.setUser({
      id: item.userId,
    });
    const tokenArr = item.token.split(" ");
    Cookies.set("token_type", tokenArr[0], {
      expires: 1 / 24,
      domain: getDomain(),
    });
    Cookies.set("access_token", tokenArr[1], {
      expires: 1 / 24,
      domain: getDomain(),
    });
    Cookies.set("refresh_token", tokenArr[2], {
      expires: 1,
      domain: getDomain(),
    });
  }

  if (returnUrl && item.userId) location.href = returnUrl;
  else router.reload();
}

export async function setCookieServerToken(req, res, authorization) {
  const [token_type, access_token, refresh_token] = authorization.split(" ");
  if (token_type && token_type !== "undefined") {
    const domain = convertDomain(req.headers["host"]);
    setCookie("token_type", token_type, {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain,
    });
    setCookie("access_token", access_token, {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain,
    });
    setCookie("refresh_token", refresh_token, {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24 * 7,
      domain: domain,
    });
  }
}
