import "react-datepicker/dist/react-datepicker.css";
import "../styles/globals.css";
import "@styles/datepicker-custom.css";
import { useEffect } from "react";
import { appWithTranslation } from "next-i18next";
import TbzMetaHead from "../components/meta/TbzMetaHead";
import TTBBMetaHead from "../components/meta/TTBBMetaHead";
import HeaderScript from "../components/layout/HeaderScript";
import HeaderTTBBScript from "@components/layout/HeaderTTBBScript";
import { useDomain } from "@modules/hooks/useDomain";
import { datadogRum } from "@datadog/browser-rum";
datadogRum.init({
  applicationId: "f35181e4-d856-4137-b2d6-b803872a0946",
  clientToken: "pub2327d7555f3c71c22bc4129a7542203d",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: "tripbtoz-www",
  env: process.env.NEXT_PUBLIC_DEV_ENV_INIT,
  version: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
  sessionSampleRate: 10,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [/^https:\/\/(dev-|stage-)?api\.tripbtoz\.com/, /^https:\/\/(dev-|stage-)?push-api\.tripbtoz\.com/, /^https:\/\/(dev-|stage-)?payments\.tripbtoz\.com/, /^https:\/\/(dev-|stage-)?www\.tripbtoz\.com\/api/, /https:\/\/.*\.triptoz\.com/]
});
function App({
  Component,
  pageProps
}) {
  const isTTBB = useDomain();

  // useEffect(() => {
  //   if (typeof window !== undefined) {
  //     if (window.location.pathname.includes("/air/reservation-confirm")) {
  //       window.location.href = `https://web.kbttbb.com/air/reservation-confirm?actionURL=https%3A%2F%2Fairs.kbcard.com%2Fair%2Fb2c%2Fauth%2FloginAction.k1%3FactionURL%3Dhttps%253A%252F%252Fairs.kbcard.com%252Fair%252Fv2%252Fb2c%252FAIR%252FMBL%252FAIRMBLLST0100000010.k1%253FKSESID%253Dair%25253Ab2c%25253ASELK138AU%25253ASELK138AU%25253ATRP001%25253A00%2526KSESID%253Dair%25253Ab2c%25253ASELK138AU%25253ASELK138AU%25253ATRP001%25253A00%26KSESID%3Dair%253Ab2c%253ASELK138AU%253ASELK138AU%253ATRP001%253A00&KSESID=air%3Ab2c%3ASELK138AU%3ASELK138AU%3ATRP001%3A00`;
  //     }
  //   }
  // }, []);

  if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (search, replacement) {
      return this.split(search).join(replacement);
    };
  }
  const siteMeta = isTTBB ? <TTBBMetaHead pageProps={pageProps} /> : <TbzMetaHead pageProps={pageProps} />;
  const siteScript = process.env.NEXT_PUBLIC_DEV_ENV !== "development" ? <>{isTTBB ? <HeaderTTBBScript /> : <HeaderScript />}</> : <></>;
  return <>
      {siteMeta}
      {siteScript}
      <Component {...pageProps} />
    </>;
}
export default appWithTranslation(App);