import Script from "next/script";
import { GA_ID_TTBB, GTM_ID_TTBB } from "@modules/lib/gtm";
function HeaderTtbbScript() {
  return <>
      <Script id="googleTagManager" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: ` 
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID_TTBB}');
          `
    }} />
      {/* ttbb google */}
      <Script id="dataLayer1" strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID_TTBB}`} />
      <Script id="dataLayer2" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `
         window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', '${GA_ID_TTBB}');
          `
    }} />
    </>;
}
export default HeaderTtbbScript;