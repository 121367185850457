import { checkoutInfo } from "./checkout";
import { hotelsInfo } from "./hotel";

export const gtagDataFormat = (type, data) => {
  if (type === "add_to_cart") {
    return checkoutInfo(data);
  } else if (type === "view_item") {
    return hotelsInfo(data);
  } else {
    return data;
  }
};
