import dayjs from "dayjs";

export const hotelsInfo = data => {
  const [hotelRateData, hotelData, searchData, locale] = data;
  return {
    currency: hotelRateData?.items[0]?.rates[0]?.currency, //KRW or USD
    value: hotelRateData?.items[0]?.rates.reduce((roomAcc, roomCur) => {
      const oneNightPrice = Math.round(
        roomCur.total_price / dayjs(searchData.endDate).diff(dayjs(searchData.startDate), "days"),
      );
      return roomAcc === 0 ? oneNightPrice : Math.min(roomAcc, oneNightPrice);
    }, 0), //호텔상세에 노출되는 최저가 (가격+세금및봉사료)
    items: [
      {
        item_id: hotelData.id, //호텔아이디
        item_name: locale === "ko-KR" ? hotelData?.name?.ko : hotelData?.name?.en,
        item_brand: hotelRateData?.items[0]?.rates[0]?.supplier || "", //공급자타입 (TIC/E/ONDA)
        item_category: hotelData.country_code, //호텔 국가코드
        item_category2: hotelData.star_rating, //성급
        quantity: searchData.roomsInfo.length, //예약 객실 수
        size: searchData.roomsInfo.reduce(
          (acc, cur) => acc + cur.people.adultCnt + cur.people.childs.length,
          0,
        ),
      }, //호텔명
    ],
  };
};
