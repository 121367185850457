export const setSessionStorage = (key, value) => {
  if (typeof window !== "undefined" && sessionStorage) {
    const val =
      typeof value === "string" || typeof value === "number"
        ? value.toString()
        : JSON.stringify(value);
    sessionStorage.setItem(key, val);
  } else {
    console.log("sessionStorage is undefined");
  }
};

export const getSessionStorage = (key, defaultValue) => {
  if (typeof window !== "undefined" && sessionStorage && !!sessionStorage.getItem(key)) {
    let jsonData = "";
    try {
      jsonData = JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      // console.log(sessionStorage.getItem(key), "is not JSON type");
      jsonData = JSON.parse(JSON.stringify(sessionStorage.getItem(key)));
    }
    return jsonData;
  } else {
    // console.log(key, "sessionStorage is undefined or value is null");
    return !!defaultValue ? defaultValue : "";
  }
};

export const removeSessionsStorage = key => {
  if (typeof window !== "undefined" && sessionStorage && !!sessionStorage.getItem(key)) {
    sessionStorage.removeItem(key);
  }
};

export const setLocalStorage = (key, value) => {
  if (typeof window !== "undefined" && localStorage) {
    const val =
      typeof value === "string" || typeof value === "number"
        ? value.toString()
        : JSON.stringify(value);
    localStorage.setItem(key, val);
  } else {
    console.log("localStorage is undefined");
  }
};

export const getLocalStorage = (key, defaultValue) => {
  if (typeof window !== "undefined" && localStorage && !!localStorage.getItem(key)) {
    let jsonData = "";
    try {
      jsonData = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      jsonData = JSON.parse(JSON.stringify(localStorage.getItem(key)));
    }
    return jsonData;
  } else {
    return !!defaultValue ? defaultValue : "";
  }
};

export const removeLocalStorage = key => {
  if (typeof window !== "undefined" && localStorage && !!localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
};
