import { getStorageInfo, STORAGE_KEY } from "@modules/utils/Adsource";
import { IsTripbtozApp } from "@modules/utils/TripbtozApp";

export const checkoutInfo = checkOutData => {
  const isApp = IsTripbtozApp();
  return {
    currency: checkOutData?.checkouts?.checkout.price.currency, //KRW or USD
    value: checkOutData?.checkouts?.checkout.price.selling_price, //선택한 룸의 요금 (가격+세금및봉사료)
    tbmeta: getStorageInfo(STORAGE_KEY.T_META_KEY)?.meta_type || "", //유입경로 (utm_source)
    platform: isApp ? "App" : "Web",
    items: [
      {
        item_id: checkOutData?.checkouts?.hotel.id, //호텔아이디
        item_name: checkOutData?.checkouts?.hotel.name?.replaceAll('"', '\\"') || "", //호텔명
        item_brand: "", //공급자타입 (TIC/E/ONDA)
        item_category: checkOutData?.checkouts?.hotel.country_code, //호텔 국가코드
        item_category2: "", //성급
        item_variant: checkOutData?.checkouts?.checkout.cancel_policy?.free_refund_policy?.after
          ? "무료취소"
          : checkOutData?.checkouts?.checkout.refundable
          ? "환불불가"
          : "취소수수료 발생", //취소수수료 (무료취소 or 환불불가 or 취소수수료 발생)
        item_list_id: checkOutData?.checkouts?.room.bed_types[0]?.bed_id, //예약하는 룸의 rate_id
        item_list_name: checkOutData?.checkouts?.room.name
          ? checkOutData?.checkouts?.room.name.replaceAll('"', '\\"')
          : "", //예약하는 룸의 이름
        check_in: checkOutData?.checkouts?.checkout.check_in, //체크인 일자
        check_out: checkOutData?.checkouts?.checkout.check_out, //체크아웃 일자
        price:
          checkOutData?.checkouts?.checkout.price.selling_price /
          checkOutData?.checkouts?.room.count, //선택한 룸의 요금 (가격+세금및봉사료)
        quantity: checkOutData?.checkouts?.room.count, //예약 객실 수
        size: checkOutData?.checkouts?.stay.num_people, //총인원
      },
    ],
  };
};
