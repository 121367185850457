import { gtagDataFormat } from "./functions/gtm";

/** ---------- GTM ID ---------- */
export const GTM_ID = "GTM-PWTCBHR";
export const GTM_ID_TTBB = "GTM-WN23T4D";
export const GA_ID_TBZ = "G-2BN6KQLKYV";
export const Google_Ads_ID_TBZ = "AW-932525294";
export const GA_ID_TTBB = "G-46PRCPRPCH";
/** ---------------------------- */

export const setDataLayer = data => {
  if (process.env.NEXT_PUBLIC_DEV_ENV !== "development") {
    const layer = window?.dataLayer || [];
    if (layer) {
      layer.push(data);
    } else {
      console.log("window.dataLayer is not defined");
    }
  }
};

export const gtagEvent = (type, data) => {
  if (process.env.NEXT_PUBLIC_DEV_ENV !== "development") {
    gtag("event", type, gtagDataFormat(type, data));
  }
};
