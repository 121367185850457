function compareVersions(v1, v2) {
  const parts1 = v1.split(".").map(Number);
  const parts2 = v2.split(".").map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;

    if (part1 > part2) return 1;
    if (part1 < part2) return -1;
  }

  return 0;
}

function versionCheck(version) {
  const appVersionMatch = window.navigator.userAgent.match(/MetaTrip\/(\d{7})/);
  // const appVersionMatch = "TripbtoziOS/3.0/MetaTrip/3002002".match(/MetaTrip\/(\d{7})/);

  if (!appVersionMatch) {
    return false;
  }

  // 앱 버전 추출 및 변환 (예: '3002002' -> '3.2.2')
  const appVersion = appVersionMatch[1]
    .replace(/^(\d)(\d{3})(\d{3})$/, "$1.$2.$3")
    .split(".")
    .map(num => parseInt(num, 10))
    .join(".");

  // 타겟 버전 변환 (예: '3.02.02' -> '3.2.2')
  const targetVersion = version
    .split(".")
    .map(num => parseInt(num, 10))
    .join(".");

  // 버전 비교
  return compareVersions(appVersion, targetVersion) >= 0;
}

export function IsTripbtozApp(version) {
  if (typeof window !== "undefined") {
    const isApp = window.navigator.userAgent.indexOf("Tripbtoz") > -1;
    const isNewApp =
      window.navigator.userAgent.indexOf("TripbtoziOS/3.0/MetaTrip") > -1 ||
      window.navigator.userAgent.indexOf("TripbtozAndroid/3.0/MetaTrip") > -1;

    if (isNewApp && version) {
      return versionCheck(version);
    }

    return isApp || isNewApp;
  } else {
    return false;
  }
}

export function IsTTBBApp(version) {
  if (typeof window !== "undefined") {
    const isApp = window.navigator.userAgent.indexOf("TTBB") > -1;
    const isNewApp =
      window.navigator.userAgent.indexOf("TTBBiOS/3.0/MetaTrip") > -1 ||
      window.navigator.userAgent.indexOf("TTBBAndroid/3.0/MetaTrip") > -1;

    if (isNewApp && version) {
      return versionCheck(version);
    }

    return isApp || isNewApp;
  } else {
    return false;
  }
}
